import { useContractWrite, useWaitForTransaction } from "wagmi";
import { CONTRACT_INFO } from "../../index";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { extractErrorMessage } from "../../../helpers/common";

const address = CONTRACT_INFO.stake.address;
const abi = CONTRACT_INFO.stake.abi;

const useDisablePayout = ({setLoader}) => {
  const {
    isError: isDisablePayoutSentError,
    data: disablePayoutSentTx,
    isLoading: isDisablePayoutTxInProgress,
    isSuccess: isDisablePayoutTxSent,
    write: disablePayout, // Function to interact with the disablePayout contract
    error: disablePayoutSentError
  } = useContractWrite({
    address,
    abi,
    functionName: "off", // Assuming this function remains the same in your ABI
  });

  const {
    isLoading: isDisablePayoutWaiting,
    isSuccess: isDisablePayoutCompleted,
    isError: isDisablePayoutError,
    error: disablePayoutTxError,
    data: disablePayoutResp
  } = useWaitForTransaction({
    hash: disablePayoutSentTx?.hash,
  });

  useEffect(()=>{
    const error =disablePayoutSentError||disablePayoutTxError
    if(error){
        toast.error(extractErrorMessage(error.message))
        setLoader(false)
    }

  },[disablePayoutSentError,disablePayoutTxError])

  return {
    // disablePayout operation
    isDisablePayoutSentError,
    disablePayoutSentTx,
    isDisablePayoutTxInProgress,
    isDisablePayoutTxSent,
    disablePayout,
    isDisablePayoutCompleted,
    isDisablePayoutError,
    isDisablePayoutWaiting,
    disablePayoutSentError,
    disablePayoutTxError,
    disablePayoutResp
  };
};

export default useDisablePayout;
